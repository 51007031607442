/* call-to-action */
.call-to-action {
    position: relative;

    display: flex;
    align-items: center;
    border-radius: .2rem;
    border: 1px solid var(--color-brown-4);
    background: var(--color-white);
    padding: 1.6rem 2.2rem 1.4rem;
    text-decoration: none;
    transition: border .2s ease-in-out;

    &::after {
        @extend %icon-content !optional;
        @extend %icon-content-arrow !optional;
        position: absolute;
        top: calc(50% - (1rem/2));
        right: .5rem;

        transform: rotate(-90deg);
        font-size: 1rem;
        color: var(--color-brown);
    }

    &:hover,
    &:focus {
        text-decoration: none;
        border-color: var(--color-brown);
    }
}

.call-to-action__image {
    display: block;
    margin-right: 1.6rem;
}

.call-to-action__content {
    display: block;
}

.call-to-action__top-text {
    display: block;
    font-family: var(--font-roboto);
    font-weight: var(--roboto-regular);
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: var(--color-gray-2);

    strong {
        text-transform: uppercase;
        font-family: var(--font-soleil);
        font-weight: var(--soleil-bold);
        font-size: 1.7rem;
        color: var(--color-brown);
    }
}

.call-to-action__bottom-text {
    display: block;
    font-family: var(--font-soleil);
    font-weight: var(--soleil-bold);
    font-size: 2.4rem;
    color: var(--color-brown);
}