/* blockquote */
.blockquote {
    display: block;
    margin: 6rem 0 5rem;
    padding: 0;
}

.blockquote__content {
    display: block;
    margin-bottom: 1.4rem;
    padding: 2.5rem 2.5rem 3rem 2.5rem;
    background: var(--color-brown);

    &,
    p {
        font-family: var(--font-roboto);
        font-weight: var(--roboto-regular);
        font-size: 1.6rem;
        font-style: italic;
        line-height: 2.5rem;
        color: var(--color-white);
    }
}

.blockquote__image {
    display: inline-block;
    vertical-align: top;
    width: 6rem;
    height: 6rem;

    img {
        border-radius: 100%;
    }
}

.blockquote__author {
    display: inline-block;
    width: calc(100% - 6.5rem);
    padding-top: .5rem;
    vertical-align: top;
}

.blockquote__title {
    display: block;
    font-family: var(--font-soleil);
    font-weight: var(--soleil-regular);
    font-size: 1.6rem;
    line-height: 2rem;
    color: var(--color-brown);
}

.blockquote__subtitle {
    font-family: var(--font-soleil);
    font-weight: var(--soleil-regular);
    font-size: 1.4rem;
    line-height: 1.7rem;
    color: var(--color-gray-2);
}
