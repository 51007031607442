/* autoprefixer: on */

/* article-wrapper */
.article-wrapper {
    @media screen and (min-width: 1100px) {
        display: grid;
    }

    grid-template-areas:
        "article    sidebar"
        "downloads  ."
        "gallery    gallery"
    ;
    grid-template-columns: auto minmax(auto, 44.8rem);
    grid-gap: 4rem;

    .article {
        grid-area: article;
    }

    .-downloads {
        grid-area: downloads;
    }

    .-gallery {
        grid-area: gallery;
        margin-bottom: 8rem;

        @media (--screen-large) {
            margin-bottom: 21rem;
        }
    }

    .-sidebar {
        grid-area: sidebar;
    }
}