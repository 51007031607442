/* aside */
.aside {
    box-sizing: border-box;
    display: block;

    @media (--screen-large) {
        padding-left: 2.8rem;
    }

    &.-sidebar {
        @media (--screen-sidebar) {
            display: flex;
        }

        > * {
            @media (--screen-sidebar) {
                flex: 1 1 300px;
                padding: 2rem;
            }
        }
    }
}