/* Animation Fade-in */
%animation-fade-in {
    /* @keyframes duration | timing-function | delay | iteration-count | direction | fill-mode | play-state | name */
    animation: .3s ease-in .5s 1 normal forwards FadeIn;
    opacity: 0;
}

@keyframes FadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
