/* Titles */
%title,
[class^="title-"] {
    -webkit-font-smoothing: auto;
    line-height: 1.25;
    margin: 0 0 1em 0;

    a:not([class]) {
        text-decoration: none;
        color: inherit;
    }

    &.-inline {
        display: inline-block;
        margin-right: 2.5rem;
    }

    &.-centered {
        text-align: center;
    }
}

.title-1 {
    font-family: var(--font-soleil);
    font-weight: var(--soleil-bold);
    font-size: 2.8rem;
    line-height: 1.5;
    color: var(--color-brown);



    @media (--screen-medium-large) {
        font-size: 5rem;
        margin-bottom: 2rem;
    }
}

.title-2 {
    font-family: var(--font-soleil);
    font-weight: var(--soleil-bold);
    font-size: 2.6rem;
    color: var(--color-brown);

    @media (--screen-medium-large) {
        font-size: 3.2rem;
    }
}

.title-3 {
    font-family: var(--font-soleil);
    font-weight: var(--soleil-bold);
    font-size: 2.2rem;
    color: var(--color-brown);
}

.title-4 {
    font-family: var(--font-soleil);
    font-weight: var(--soleil-bold);
    font-size: 2rem;
    color: var(--color-brown);
}

.title-5 {
    font-size: 1.8rem;
}

.title-6 {
    font-size: 1.6rem;
}

h1 {
    @extend %title;
    @extend .title-1;
}

h2 {
    @extend %title;
    @extend .title-2;
}

h3 {
    @extend %title;
    @extend .title-3;
}

h4 {
    @extend %title;
    @extend .title-4;
}

h5 {
    @extend %title;
    @extend .title-5;
}

h6 {
    @extend %title;
    @extend .title-6;
}
