/* block-links */
.block-links {
    display: block;
}

.block-links__item {
    position: relative;
    display: block;
    padding: 1.6rem 2.5rem;
    text-decoration: none;
    background: color-mod(var(--color-brown-5) a(20%));
    cursor: pointer;

    &:hover,
    &:focus {
        text-decoration: none;

        .block-links__title {
            text-decoration: underline;
            text-decoration-color: var(--color-brown);
        }
    }

    &:first-child {
        border-top: 1px solid var(--color-brown-5);
    }

    &:nth-child(even) {
        background: var(--color-brown-5);
    }

    &::after {
        @extend %icon-content !optional;
        @extend %icon-content-arrow !optional;
        position: absolute;
        top: 50%;
        right: 1rem;

        transform: rotate(-90deg) translateY(-50%);
        font-size: .6rem;
        line-height: 0;
        color: var(--color-brown);
    }
}

.block-links__title {
    display: block;
    font-family: var(--font-roboto);
    font-weight: var(--roboto-regular);
    font-size: 2rem;
    line-height: 2.4rem;
    color: var(--color-brown);
}

.block-links__place {
    font-family: var(--font-roboto);
    font-weight: var(--roboto-regular);
    font-size: 1.6rem;
    line-height: 2.4rem;
    color: var(--color-brown-3);
}
