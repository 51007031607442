/* alphabetical-header */
.alphabetical-header {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 1rem;
    padding-top: 1rem;
    background: var(--color-white);
    border-bottom: 1px solid var(--color-brown-4);
    @media (--screen-medium-large) {
        top: 12rem;
    }
}

.alphabetical-header__letters {
    position: sticky;
    top: 6rem;
    display: block;
    width: 100%;
    background: var(--color-white);
    padding: 1rem 0;

    a {
        display: inline-block;
        margin-right: .7rem;
    }

    @media (--screen-medium-large) {
        top: 4rem;
        padding: 3rem 0 1.5rem 0;
    }
}

.alphabetical-header__current {
    display: block;
    font-family: var(--font-soleil);
    font-weight: var(--soleil-semi-bold);
    font-size: 2rem;
    line-height: 2.5rem;
    color: var(--color-brown);
}

.alphabetical-header__amount {
    display: block;
    margin-left: auto;
    font-family: var(--font-roboto);
    font-weight: var(--roboto-regular);
    font-size: 1.6rem;
    line-height: 1.9rem;
    color: var(--color-brown-3);
}