/* Article */
/*
    1. A lot of articles don't have paragraph elements so give the titles the same top margin as the margin-bottom of
       paragraphs to fix this.
 */
article,
.article, .cke_editable {
    @media (--screen-large) {
        max-width: 75rem;
        figure {
            max-width: 75rem;
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin-bottom: 2.5rem;
    }

    h2 {
        max-width: 75rem;
        margin-top: 2.4rem;  /* 1 */
    }

    h3,
    h4,
    h5,
    h6 {
        max-width: 62rem;
        margin-top: 2.4rem; /* 1 */
    }

    img {
        max-width: 100%;
        height: auto;
    }

    p {
        + h2,
        + h3,
        + h4,
        + h5,
        + h6 {
            margin-top: 6rem;
        }
    }

    &.-fixed-width {
        display: block;
        max-width: 75.5rem;
        margin: 0 auto;
    }
}
