/* container */
%container {
    box-sizing: border-box;
    display: block;
    width: 100%;
    max-width: 1240px + (27px * 2);
    margin: 0 auto;
    padding-left: 2rem;
    padding-right: 2rem;

    @media (--screen-medium-large) {
        padding-left: 2.7rem;
        padding-right: 2.7rem;
    }
}

.container {
    @extend %container;

    &.-no-max {
        max-width: initial;
    }

    &.-no-mobile-padding {
        @media (--screen-mini) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
