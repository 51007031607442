.book-index {
    margin: 3em auto;
    max-width: 650px;

    ol {
        margin-left: 1.5em;
        font-size: 1.6rem;

        @media (--screen-mini-small) {
            font-size: 1.3rem;
            margin-left: 0;
        }
    }

    li {
        margin-bottom: 0;

        @media (--screen-mini-small) {
            padding-left: 0;
        }
    }

    > ol {
        list-style-type: upper-roman;
        position: relative;

        > li {
            font-weight: bold;
            font-size: 110%;
            margin-bottom: 1em;
            padding-right: 3rem;

            > ol {
                list-style-type: decimal;
                margin-left: 1.5em;

                @media (--screen-mini-small) {
                    margin-left: 0;
                }

                > li {
                    font-size: 100%;
                    font-weight: normal;
                    margin-bottom: .5em;

                    .book-index__page-number {
                        right: -3rem;
                    }

                    > ol {
                        list-style-type: lower-alpha;

                        > li {
                            font-style: italic;
                            color: #555;
                        }
                    }
                }
            }
        }
    }

    &__page-number {
        position: absolute;
        right: 0;
        font-style: normal;
    }
}
