/* Helpers */
.h-hide {display: none !important;}
.h-hide-mini {  @media (--screen-mini) { display: none !important; } }
.h-hide-mini-small {  @media (--screen-mini-small) { display: none !important; } }
.h-hide-mini-medium {  @media (--screen-mini-medium) { display: none !important; } }
.h-hide-small {  @media (--screen-small) { display: none !important; } }
.h-hide-small-medium {  @media (--screen-small-medium) { display: none !important; } }
.h-hide-small-large {  @media (--screen-small-large) { display: none !important; } }
.h-hide-medium {  @media (--screen-medium) { display: none !important; } }
.h-hide-medium-large {  @media (--screen-medium-large) { display: none !important; } }
.h-hide-large {  @media (--screen-large) { display: none !important; } }

// Margins
@for $i from 0 through 20 {
    .h-margin-bottom-#{$i} { margin-bottom: 1rem * $i !important; }
}

.h-no-padding-bottom {
    padding-bottom: 0;
}

// Text
.h-text-centered {
    text-align: center;
}

.h-text-right {
    text-align: right;
}

.h-text-white {
    color: var(--color-white);
}

.h-text-green {
     color: var(--color-green);
 }

.h-text-orange-2 {
    color: var(--color-orange-2);
}

.h-text-red {
    color: var(--color-red);
}

.h-text-gray {
    color: var(--color-gray);
}

.h-keep-small {
    font-size: 1.6rem;
}

.h-helper {
    font-size: 10px;
    color: var(--color-gray);
}

// Animation
.h-loading {
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        width: 40px;
        height: 40px;
        margin: -60px -20px;
        background-color: var(--color-black);
        border-radius: 100%;
        animation: animationScaleOut 1.0s infinite ease-in-out;
    }
}

@keyframes animationScaleOut {
    0% {
        transform: scale(0.0);
    }
    100% {
        transform: scale(1.0);
        opacity: 0;
    }
}

// Backgrounds
.h-background-white {
    background-color: var(--color-white);
}

// hide from view, don't give any width/height props
.h-hide-from-view {
    display: none;
    //font: 0/0 sans-serif;
    //color: transparent;
    //background: transparent;
    //opacity: 0;
    //width: 0;
    //height: 0;
    //overflow: hidden;
}

.h-visually-hidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}