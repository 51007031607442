/* Body */
body,
.body {
    position: relative;
    width: 100%;
    margin: 0 auto;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    font-family: var(--font-roboto);
    font-size: 1.7rem;
    line-height: 2.9rem;

    word-wrap: break-word; // Don't let long words break the layout on small area's

    height: 100vh;
    -webkit-overflow-scrolling: touch;

    // Because of fixed header
    @media screen and (min-height: 480px) and (--screen-medium-large) {
        padding-top: 12.8rem;
    }

    @media screen and (min-height: 480px) and (--screen-mini-small) {
        padding-top: 6.1rem;
    }

    &.-no-overflow {
        position: fixed;
        overflow: hidden;
        height: 100vh;

        @supports (-webkit-overflow-scrolling: touch) {
            main {
                opacity: 0;
            }
        }
    }
}
