.video {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;

    margin-bottom: 20px;
    max-width: 79.6rem;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &.-centered {
        margin: 0 auto;
    }


    &.-full-width {
        min-width: calc(100vw - 2rem);
        @media (--screen-medium-large) {
            min-width: 75vw;
        }
    }

    &.-popup {
        min-width: calc(100vw - 4rem);
        margin: auto;

        @media (--screen-medium-large) {
            min-width: 75vw;
        }
    }
}