/* comment */
.comment {
    display: block;
    margin-bottom: 2.8rem;

    &::before { // Fix anchor location because of sticky header
        display: block;
        content: " ";
        margin-top: -75px;
        height: 75px;
        visibility: hidden;
    }
}

.comment__head {
    display: block;
    margin-bottom: 1rem;
    @media (--screen-medium-large) {
        display: flex;
    }
}

.comment__author {
    display: block;
    font-family: var(--font-soleil);
    font-weight: var(--soleil-book);
    font-size: 1.8rem;
    line-height: 2.3rem;
    color: var(--color-brown);

    margin-right: auto;
}

.comment__date {
    display: block;
    font-family: var(--font-soleil);
    font-weight: var(--soleil-regular);
    font-size: 1.4rem;
    line-height: 2.3rem;
    color: var(--color-brown-3);
}

.comment__content {
    position: relative;
    display: block;
    background: var(--color-brown-5);
    padding: 2rem 3.5rem;

    > * {
        font-size: 1.6rem;
        line-height: 2.5rem;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &::after {
        content: '';
        position: absolute;
        top: -1.2rem;
        left: 1rem;
        z-index: 1;

        display: block;
        width: 0;
        height: 0;
        border: .6rem solid transparent;
        border-bottom-color: var(--color-brown-5);
    }
}
