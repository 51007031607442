/* back-link */
.back-link {
    display: inline-block;
    font-family: var(--font-roboto);
    font-weight: var(--roboto-regular);
    font-size: 1.4rem;
    line-height: 2.5rem;
    color: var(--color-brown-3);

    &::before {
        @extend %icon-content !optional;
        @extend %icon-content-arrow !optional;
        transform: rotate(90deg);
        font-size: .6rem;
        color: var(--color-brown-3);

        position: relative;
        top: 2px;
        padding-right: .5rem;
    }
}