:root {
    --cookie-consent-max-width: 42em;
    --cookie-consent-color: var(--color-brown);
    --cookie-consent-background-color: var(--color-brown-5);
    --cookie-consent-options-color: inherit;
    --cookie-consent-options-background-color: var(--color-brown-4);
    --cookie-consent-iframe-template-background-color: var(--color-blue);
    --cookie-consent-iframe-template-color: var(--color-brown);
    --cookie-consent-iframe-template-max-width: 100%;
}

.cookie-consent {
    position: fixed;
    z-index: var(--cookie-consent-z-index, 999);
    inset-block-end: var(--cookie-consent-bottom, 0);
    transform: var(--cookie-consent-transform, none);

    overflow-y: auto;

    box-sizing: border-box;
    inline-size: 100%;
    max-inline-size: var(--cookie-consent-max-width);
    max-block-size: 100%;
    margin-block: var(--cookie-consent-margin-block, auto 0);
    margin-inline: var(--cookie-consent-margin-inline, auto 0);
    padding: var(--cookie-consent-padding, 2em);

    color: var(--cookie-consent-color);

    background-color: var(--cookie-consent-background-color);
    border: 0;
    box-shadow: 0px 0px 2px 0px var(--color-brown);

    p {
        color: inherit;
    }

    a:not([class]) {
        color: var(--cookie-consent-link-color, inherit);
    }

    &.-is-modal {
        --cookie-consent-margin-inline: auto;

        @media (min-width: 45rem), (min-height: 31rem) {
            --cookie-consent-bottom: 50%;
            --cookie-consent-transform: translate3d(0, calc(50% + .5px), 0); // Fix blurry rendering in Chrome
        }
    }

    &:not(.-is-modal) {
        @media (max-width: 45rem), (max-height: 31rem) {
            --cookie-consent-max-width: none;
        }
    }
}

.cookie-consent__title {
    @extend .title-2;

    margin-block: 0 1rem;
    color: inherit;
}

.cookie-consent__form {
    fieldset {
        border: 0;
        padding: 0;
    }
}

.cookie-consent__options {
    margin: 2em 0;
    padding: 1em;
    color: var(--cookie-consent-options-color);
    background: var(--cookie-consent-options-background-color);
}

.cookie-consent__option-description {
    display: block;
    margin-block: 0;
    font-size: 90%;
}

.cookie-consent__buttons:not([hidden]) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .-general {
        margin-block-start: 1em;
    }

    .anchor {
        border: 0;
        background-color: transparent;
        color: currentcolor;
        font-size: 1.6rem;
        cursor: pointer;

        &:hover, &:focus {
            text-decoration: underline;
        }
    }
}

template.-pending-consent {
    cursor: pointer;

    position: relative;

    container-type: inline-size;
    display: block;

    max-inline-size: var(--cookie-consent-iframe-template-max-width);
    min-block-size: 300px;
    margin-inline: auto;
    padding-block-end: var(--cookie-consent-iframe-template-bottom-whitespace, 4rem);

    &::before {
        content: attr(title);

        position: absolute;
        z-index: 1;

        display: flex;
        align-items: center;
        justify-content: center;

        box-sizing: border-box;
        inline-size: 100%;
        block-size: 100%;
        padding: 2em;

        color: var(--cookie-consent-iframe-template-color, inherit);
        text-align: center;

        background-image: var(--cookie-consent-cover-image);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        @supports (container-type: inline-size) {
            padding-inline: Max(2em, calc((100cqw - 26em) / 2)); // sass-lint:disable-line
        }
    }

    &::after {
        content: '';

        position: absolute;
        inset: 0;

        display: block;

        background-color: var(--cookie-consent-iframe-template-background-color);
        background-image: url('data:image/svg+xml;utf8,<svg width="95" height="94" opacity=".05" viewBox="0 0 95 94" fill="none" xmlns="http://www.w3.org/2000/svg"><rect x="2.5" y="2" width="90" height="90" rx="45" stroke="white" stroke-width="4"/><rect x="85.0717" y="21.5332" width="1.93351" height="89.1071" transform="rotate(58.6176 85.0717 21.5332)" fill="white"/><path fill-rule="evenodd" clip-rule="evenodd" d="M52.0998 41.6598L53.9138 42.8164L61.3666 38.2703L59.5509 37.1148L52.0998 41.6598ZM51.1733 41.0691L39.8348 33.84V47.9854L32.0015 52.7636V19.5833L58.6235 36.5247L51.1733 41.0691ZM32.0015 53.9194L39.8348 49.1412V51.4043L32.0015 56.1825V53.9194ZM39.8348 60.16V52.6419L32.0015 57.4201V74.4167L75.0848 47L62.3597 38.9022L54.9059 43.4489L60.4756 47L39.8348 60.16Z" fill="white"/></svg>');
        background-position: center;
        background-size: 40%;
        background-repeat: no-repeat;
    }

    &[data-consent-type='video'] {
        aspect-ratio: var(--cookie-consent-cover-aspect-ratio, 16 / 9);
        padding-block-end: 0;
    }

    &:hover, &:focus, &:active {
        &::before {
            text-decoration: underline;
        }
    }
}
