/* stylelint-disable declaration-colon-space-after, max-empty-lines */

:root {
    --color-white                   : #FFFFFF;
    --color-black                   : #000000;

    --color-paragraph               : #7C7C7C; // used

    --color-yellow                  : #FFB82E; // used
    --color-yellow-2                : #FFEC01; // used, mark

    --color-orange                  : #FFA82F; // used
    --color-orange-2                : #DF7408; // used

    --color-red                     : #FF003D; // used, form__message
    --color-red-2                   : #FF0024; // used

    --color-green                   : #408100;

    --color-brown                   : #54342F; // used
    --color-brown-2                 : #3F231F; // used
    --color-brown-3                 : #7A7171; // used
    --color-brown-4                 : #DBCDB5; // used
    --color-brown-5                 : #EBE1CE; // used

    --color-blue                    : #41B1E8; // used
    --color-blue-2                  : #0E628C; // used
    --color-blue-3                  : #168BC7; // used
    --color-blue-4                  : #0D5376; // used

    --color-gray                    : #EFEDEA; // used
    --color-gray-2                  : #7C7C7C; // used
    --color-gray-3                  : #C2D7E2; // used
    --color-gray-4                  : #8A8A8A; // used

    --color-autofill                : #F9F9F9;

    --color-info                    : #56ACE0;
    --color-notice                  : #333333;
    --color-error                   : #EC3A30;
    --color-success                 : var(--color-green);
    --color-warning                 : #E9A33C;

    --color-facebook                : #3B5998;
    --color-twitter                 : #1DA1F2;
    --color-youtube                 : #FF0000;
    --color-linkedin                : #0077B5;
    --color-inventis                : #0E7FD2; // credits

    --font-soleil                  : sans-serif;
    --soleil-light                 : 300;
    --soleil-regular               : 400;
    --soleil-medium                : 500;
    --soleil-book                  : 500;
    --soleil-semi-bold             : 600;
    --soleil-bold                  : 700;
    --soleil-extra-bold            : 800;

    --font-roboto                  : Roboto, sans-serif;
    --roboto-regular               : 400;
    --roboto-bold                  : 700;
}

@custom-media --screen-large        (width >= 850px);

@custom-media --screen-lower-large   (width >= 851px) and (width < 950px);
@custom-media --screen-upper-large  (width >= 951px) and (width < 1200px);

@custom-media --screen-medium-large (width >= 630px);
@custom-media --screen-medium       (width >= 630px) and (width < 850px);

@custom-media --screen-small-large  (width >= 420px);
@custom-media --screen-small-medium (width >= 420px) and (width < 850px);
@custom-media --screen-small        (width >= 420px) and (width < 630px);

@custom-media --screen-mini-medium  (width < 850px);
@custom-media --screen-mini-small   (width < 630px);
@custom-media --screen-mini         (width < 420px);

@custom-media --screen-sidebar      (width >= 630px) and (width < 1100px);

@custom-media --timetable-desktop   (width >= 900px);
@custom-media --timetable-mobile    (width < 900px);

// Change this value to change media-query size of masthead alone
$query-masthead-min: 985;
$query-masthead: $query-masthead-min + 'px';
@custom-media --query-masthead       (width < #{$query-masthead});
@custom-media --query-masthead-large (width >= #{$query-masthead});
@custom-media --query-masthead-logo (width >= 1150px );
