/* box */
.box {
    box-sizing: border-box;
    display: block;
    width: 100%;
    padding: 2rem 3rem 3rem 3rem;

    &.-blue {
        border-radius: .2rem .2rem 0 0;
        background: var(--color-blue-2);
    }

    &.-blue-2 {
        border-radius: 0 0 .2rem .2rem;
        background: var(--color-blue-4);
    }

    &.-brown {
        border-radius: .2rem;
        background: var(--color-brown-5);
    }

    &.-dark-brown {
        border-radius: .2rem;
        background: var(--color-brown);
        color: var(--color-white);
    }

    &.-alternating {
        padding: 0;
        border-top: 1px solid var(--color-brown-4);
    }

    &.-no-break {
        display: block;
        padding: 0;

        -webkit-column-break-inside: avoid; /* Chrome, Safari */
        page-break-inside: avoid;           /* Theoretically FF 20+ */
        break-inside: avoid-column;         /* IE 11 */
    }

    &.-padded-vertical {
        padding-left: 0;
        padding-right: 0;
    }
}

.box--border {
    border: 1px solid black;
    padding: 1em 2em;
    margin: 0 0 2em 0;
}
