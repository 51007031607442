/* Button */
%button-reset {
    transition: all .2s ease;

    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    padding: 0;
    border: 0;

    color: inherit;
    background: transparent;

    font-size: inherit;
    font-weight: inherit;
    line-height: 1;
    text-decoration: none;
    outline: none;

    vertical-align: middle;
    &:hover, &:focus {
        text-decoration: none;
    }
}


%button,
.button {
    @extend %button-reset;
    position: relative;

    box-sizing: border-box;
    border-radius: .2rem;
    font-family: var(--font-soleil);
    font-weight: var(--soleil-bold);
    font-size: 1.6rem;
    line-height: 1.25;

    padding: 1.7rem 2.1rem;
    color: var(--color-white);
    background: var(--color-blue);

    .icon {
        position: relative;
        top: -.3rem;
        font-size: .7rem;
    }

    &:hover,
    &:focus {
        background: color-mod(var(--color-blue) blend(black 10%));
    }

    &:active {
        background: color-mod(var(--color-blue) blend(black 20%));
    }

    &.-search-box,
    &.-filter-box,
    &.-newsletter {
        white-space: nowrap;
        padding: 1.2rem 1.5rem;
        border-radius: 0 2px 2px 0;
    }

    &.-show-hide-password {
        padding: 0.4rem 1rem;
        float: right;
        font-size: 1.3rem;
        font-weight: 400;
        background: var(--color-gray);
        &:hover, &:focus {
            background: var(--color-gray-2);
        }
    }

    &.-full-width {
        width: 100%;
        text-align: center;
    }

    &.-left-margin {
        margin-left: 2rem;
    }

    &.-no-style,
    &.-no-style:hover,
    &.-no-style:focus {
        border-radius: 0;
        bottom: 0;
        margin: 0;
        padding: 0;
        border: 0;
        background: none;
        color: var(--color-primary);
        font-weight: 400;
    }

    &.-loading[disabled] {
        position: relative;
        color: transparent;

        &:hover, &:focus {
            color: transparent;
        }

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            margin: -20px -20px;
            background-color: var(--color-white);
            border-radius: 100%;
            animation: scaleout 1.0s infinite ease-in-out;
        }
    }

}

@keyframes scaleout {
    0% {
        transform: scale(0.0);
    }
    100% {
        transform: scale(1.0);
        opacity: 0;
    }
}
