/* col */
.col {
    &.-spacer {
        display: block;
        min-width: 8rem;
    }

    &.-push-right {
        margin-right: auto;
    }

    &.-fixed-width {
        width: 100%;
        max-width: 75rem;
    }

    &.-centered {
        margin: 0 auto;
    }

    &.-fill {
        display: block;
        width: 100%;
    }

    &.-pulled-up {
        @media (--screen-large) {
            margin-top: -8rem;
            position: relative;
            z-index: 1;
        }
    }
}