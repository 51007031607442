.category-list {
    column-width: 10em;
    list-style: none;
    margin: 0;
    padding: 0;

    > li {
        display: block;
        margin-bottom: .5em;

        > a {
            line-height: 1.5;
        }
    }
}
